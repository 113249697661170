<template>
    <BT-Blades
        v-if="currentLocationID != null"
        :maximizedCount="4"
        :anchorBlades="[{ bladeName: 'packing-list'}]">
        <template v-slot="bladesData">
            <BT-Blade-Items
                addBladeName="packing"
                bladeName="packing-list"
                :bladesData="bladesData"
                canSearch
                canSearchLocal
                navigation="stock-consignments"
                :onCanPull="() => { return currentLocationID != null }"
                :params="{ includeDetails: false, filterBy: 'Purchase Orders,Has Supply Location,Needs Packing', locationID: currentLocationID }"
                showList
                title="Consignments"
                :defaultBladeWidth="350">
                <template v-slot:listItem="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                        <v-list-item-subtitle>CO# {{ item.consignmentNumber }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>{{ item.packedOn != null ? 'Packed' : 'Pending' }}</v-list-item-action-text>
                        {{ item.dueDate | toShortDate }}
                    </v-list-item-action>
                </template>
            </BT-Blade-Items>
            <Packing-Blade
                :bladesData="bladesData" />
        </template>
    </BT-Blades>
</template>

<script>
export default {
    name: 'Packing-Hub',
    components: {
        PackingBlade: () => import('~home/packing/Packing-Blade.vue')
    },
    data: function() {
        return {
            consignmentIDs: [],
            currentLocationID: null,
            isLoading: false,
            journeyIDs: [],
            msg: null,
            pickingSlips: [],
            pickingSlipToggle: false,
            releaseIDs: [],
            showToggle: false
        }
    },
    async mounted() {
        if (this.currentLocationID == null) {
            this.currentLocationID = this.selectedLocationID();

            if (this.currentLocationID == null) {
                this.currentLocationID = await this.$selectItem({
                    navigation: 'locations', 
                    itemText: 'locationName', 
                    itemValue: 'id',
                    required: true });
            }
        }
    },
    methods: {
        consignmentSelected(bladeData, item) {
            if (item != null) {
                this.consignmentIDs.push(item.id);
                this.pickingSlips.push({
                    id: item.id,
                    slipType: 'consignment',
                    isLoaded: false,
                    title: '#' + item.consignmentNumber + ': ' + item.buyer.companyName,
                    canBulk: false,
                    consignmentIDs: [item.id],
                    consignmentID: item.id,
                    filteredConsignments: [],
                    filteredConsignmentOrderItems: [],
                    isBulk: true,
                    refreshToggle: false
                })

                this.pickingSlipToggle = !this.pickingSlipToggle;
            }
        },
        async journeySelected(bladeData, item) {
            if (item != null) {
                this.journeyIDs.push(item.id);
                var consignmentIDsRes = await this.$BlitzIt.api.get('journeys', '/GetConsignmentIDs/GetConsignmentIDs/' + item.id, null, null);
                this.consignmentIDs.push(...consignmentIDsRes.data.data);
                this.consignmentIDs = [new Set(...this.consignmentIDs)];
                this.pickingSlips.push({
                    id: item.id,
                    slipType: 'journey',
                    isLoaded: false,
                    title: item.journeyName,
                    canBulk: consignmentIDsRes.data.data.length > 1,
                    consignmentIDs: consignmentIDsRes.data.data,
                    consignmentID: consignmentIDsRes.data.data.length == 1 ? consignmentIDsRes.data.data[0] : null,
                    filteredConsignments: [],
                    filteredConsignmentOrderItems: [],
                    isBulk: true,
                    refreshToggle: false
                });

                this.pickingSlipToggle = !this.pickingSlipToggle;
            }
        },
        async releaseSelected(bladeData, item) {
            if (item != null) {
                this.releaseIDs.push(item.id);
                var consignmentIDsRes = await this.$BlitzIt.api.get('releases', '/GetConsignmentIDs/GetConsignmentIDs/' + item.id, null, null);
                if (consignmentIDsRes.length == 0) {
                    console.log('no ids found');
                }
                this.consignmentIDs.push(...consignmentIDsRes.data.data);
                this.consignmentIDs = [new Set(...this.consignmentIDs)];
                this.pickingSlips.push({
                    id: item.id,
                    slipType: 'release',
                    isLoaded: false,
                    title: 'Release',
                    canBulk: consignmentIDsRes.data.data.length > 1,
                    consignmentIDs: consignmentIDsRes.data.data,
                    consignmentID: consignmentIDsRes.data.data.length == 1 ? consignmentIDsRes.data.data[0] : null,
                    filteredConsignments: [],
                    filteredConsignmentOrderItems: [],
                    isBulk: consignmentIDsRes.data.data.length > 1,
                    refreshToggle: false
                });
                
                this.pickingSlipToggle = !this.pickingSlipToggle;
            }
        },
    }
}
</script>